import React, { useCallback, useMemo } from 'react';
import { navigate } from 'gatsby';

const JobsList = ({ jobs }) => {
  const handleJobLink = useCallback(link => navigate(link), []);

  return useMemo(
    () =>
      jobs &&
      jobs.length > 0 &&
      jobs.map(
        job =>
          job.jobs.length && (
            <div className="job-wrapper" key={job.name}>
              <h4>{job.name}</h4>
              {job.jobs.map(pos => (
                <div
                  key={pos.absolute_url}
                  className="job-item"
                  onClick={() => handleJobLink(pos.absolute_url)}
                >
                  <div className="left">
                    <a href={pos.absolute_url}>{pos.title}</a>
                    <p className="d-block d-md-none">{pos.location.name}</p>
                  </div>
                  <div className="right">
                    <p className="d-none d-md-block">{pos.location.name}</p>
                    <a href={pos.absolute_url}>Apply</a>
                  </div>
                </div>
              ))}
            </div>
          )
      ),
    [handleJobLink, jobs]
  );
};

export default JobsList;
