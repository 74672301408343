import styled, { css } from 'styled-components';

export const customSectionStyles = css`
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(249, 251, 254, 1) 21%
  );
`;

export const JobContainer = styled.div`
  margin: 0 auto;

  .section-title {
    margin-bottom: 40px;
    @media (max-width: 768px) {
      margin-bottom: 24px;
    }
  }

  .filter-wrapper {
    display: flex;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .filter {
      margin-right: 30px;
      @media (max-width: 768px) {
        margin-right: 0;
        margin-top: 16px;
      }
    }

    .filter-label {
      margin-bottom: 12px;
    }

    .multi-select {
      .select-box {
        display: flex;
        justify-content: space-between;
        width: 270px;
        background: #ffffff;
        padding: 16px;
        border: 1px solid #bdbdbd;
        text-transform: none;
        font-size: 18px;
        line-height: 22px;

        &.open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .checkboxes {
        display: flex;
        flex-direction: column;
        width: 270px;
        position: absolute;
        background-color: var(--white);
        border: 1px solid #bdbdbd;
        border-top: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        max-height: 605px;
        overflow: auto;
        z-index: 1000;

        .no-filter {
          padding: 16px;
        }

        .checkbox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 16px;

          &:nth-child(even) {
            background: #f9fbfe;
          }

          label {
            margin-bottom: 0;
            flex-grow: 1;

            & span:last-of-type {
              font-size: 16px;
            }
          }

          .res-count {
            margin-bottom: 0;
            font-size: 16.2px;
            color: #7b848f;
          }
        }
      }
    }
  }

  .result-wrapper {
    p {
      margin-bottom: 0;
    }
    .result-text {
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        align-items: flex-start;
        flex-direction: column;
      }

      button {
        padding: 0 16px;
        text-transform: none;
        color: #1c3fff;
        font-weight: bold;
        font-size: 18px;
        text-decoration: none;
        &:active,
        &:focus {
          border: none;
          outline: 0;
          box-shadow: none;
        }

        @media (max-width: 768px) {
          padding: 4px 0;
        }
      }
    }
  }

  .job-wrapper {
    margin-top: 56px;
    h4 {
      margin-bottom: 32px;
    }

    .job-item {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      border-top: 1px solid #d8dae6;
      cursor: pointer;
      &:last-child {
        border-bottom: 1px solid #d8dae6;
      }
      &:hover {
        background: var(--accent-color);
      }
      p {
        margin-bottom: 0;
      }
      .left,
      .right {
        width: 50%;
        @media (max-width: 768px) {
          width: auto;
        }
      }

      .right {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .joblist-footer {
    display: flex;
    justify-content: center;
    margin-top: 56px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const HeadingUnit = styled.div`
  padding-bottom: 56px;

  h2 {
    padding-bottom: 18px;
  }

  .subhead {
    font-weight: 400;
  }
`;

export const Row = styled.div`
  justify-content: center;
  text-align: center;
`;

export const Section = styled.section`
  width: 100%;
  h3 {
    width: 100%;
    height: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 35.16px;
    line-height: 39px;
    color: #2c3641;
    margin-bottom: 40px;
  }

  h6 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-transform: none;
    letter-spacing: 0px;
  }
  .Listing-Category {
    font-weight: 700;
    padding: 24px;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
    height: 70px;
  }

  .List-Row {
    display: flex;
    width: 100%;
    flex-basis: auto;
    padding: 20px;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: none;
    }

    .List-Position {
      flex-basis: 45%;
    }

    .List-Location {
      padding-left: 24px;
      flex-basis: 45%;
    }

    .List-Link {
      padding-left: 32px;
      flex-basis: 10%;
      white-space: nowrap;

      &::after {
        display: inline-block;
        content: url('../../images/arrow-min.svg');
        position: absolute;
        right: 0;
        top: 1px;
        transition: transform 0.2s;
      }

      a {
        text-decoration: none;

        &:hover {
          color: #1c3fff;
          opacity: 100%;
        }
      }
    }
  }
`;

export const ListingCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170px;
  box-shadow: 0 12px 16px rgba(106, 114, 158, 0.1);
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: 16px;
`;
