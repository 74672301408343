import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import axios from 'axios';
import { useLocation } from '@reach/router';
// import { captureException } from '@sentry/gatsby';

import { colors } from '../../styles/atoms/colors';

import Section from '../../molecules/Section/Section';

import JobsList from './JobsList';
import Filters from './Filter/Filters';
import ResultFilterText from './Filter/ResultFilterText';

import {
  customSectionStyles,
  JobContainer,
} from '../../styles/Components/JobsComponent/JobsStyles';

import Link from '../../utils/Link';
import useOutsideClick from '../../utils/useOutsideClick';
import { scrollToEl } from '../../utils/scrollTo';

const JobsComponent = ({ component: { id } }) => {
  const [careers, setCareers] = useState();
  const [openLocation, setOpenLocation] = useState(false);
  const [openTeam, setOpenTeam] = useState(false);
  const [labelLocation, setLabelLocation] = useState('All locations');
  const [labelTeam, setLabelTeam] = useState('All teams');
  const [locationItems, setLocationItems] = useState([]);
  const [teamItems, setTeamItems] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [list, setList] = useState();
  const [newList, setNewList] = useState([]);
  const jobs = [];
  const filterRef = useRef();
  const urlFilter = useLocation().hash.split('#')[1];

  const getCareers = useCallback(async () => {
    await axios
      .get('https://api.greenhouse.io/v1/boards/shopmonkey/departments')
      .then(result =>
        setCareers(
          result.data?.departments.filter(item => item.jobs.length > 0)
        )
      )
      .catch(err => console.log(`Careers Error - ${err}`));
  }, []);

  useEffect(() => {
    getCareers().then();
  }, [getCareers]);

  useEffect(() => {
    if (careers) {
      careers.map(job => {
        job.jobs.map(j => jobs.push(j));
      });
    }
    // eslint-disable-next-line
  }, [careers]);

  useEffect(() => {
    if (jobs.length > 0) {
      const res = jobs.reduce((carry, element) => {
        element.metadata.forEach(m => {
          carry[m.value] = carry[m.value] || [];
          carry[m.value].push({ ...element });
        });

        return carry;
      }, {});
      setList(res);
    }
  }, [jobs]);

  useEffect(() => {
    const temp = [];
    if (list) {
      Object.keys(list).forEach(key => {
        temp.push({ name: key, jobs: list[key] });
      });
    }
    setNewList(temp);
  }, [list]);

  useEffect(() => {
    const isJobListUrl = urlFilter && urlFilter === 'jobsList';
    if (isJobListUrl) {
      scrollToEl('jobsList');
    }
    const isCustomUrl =
      urlFilter && urlFilter !== 'jobsList' && locationItems.length > 0;

    if (isCustomUrl) {
      const filteredLocation = locationItems.find(loc =>
        loc.name.toLowerCase().includes(urlFilter.toLowerCase())
      );
      if (filteredLocation?.name) {
        setSelectedLocation([filteredLocation?.name]);
        setLabelLocation('1 filter applied');
        scrollToEl('jobsList');
      }
    } else {
      setSelectedLocation([]);
      setLabelLocation('All locations');
    }
  }, [locationItems, urlFilter]);

  const handleClickOutside = useCallback(() => {
    if (selectedLocation.length > 0) {
      setLabelLocation(
        `${selectedLocation.length} ${
          selectedLocation.length === 1 ? 'filter' : 'filters'
        } applied`
      );
    } else {
      setLabelLocation('All locations');
    }
    if (selectedTeam.length > 0) {
      setLabelTeam(
        `${selectedTeam.length} ${
          selectedTeam.length === 1 ? 'filter' : 'filters'
        } applied`
      );
    } else {
      setLabelTeam('All teams');
    }
    setOpenLocation(false);
    setOpenTeam(false);
  }, [selectedLocation, selectedTeam]);

  useOutsideClick(filterRef, handleClickOutside);

  useEffect(() => {
    const locArr = [];
    const teamArr = [];
    const newLocArr = [];
    const newTeamArr = [];
    const locations = {};
    const teams = {};

    if (careers) {
      careers.map(i =>
        i.jobs.map(job => locArr.push(job.location.name.trim()))
      );
      careers.map(i =>
        i.jobs.map(job =>
          job.metadata.map(
            m => m.name.trim() === 'Team' && teamArr.push(m.value)
          )
        )
      );

      if (locArr) {
        locArr.sort().forEach(x => {
          locations[x] = (locations[x] || 0) + 1;
        });
        for (const key of Object.keys(locations)) {
          newLocArr.push({ name: key, count: locations[key] });
        }
        setLocationItems(newLocArr);
      }

      if (teamArr) {
        teamArr.sort().forEach(x => {
          teams[x] = (teams[x] || 0) + 1;
        });
        for (const key of Object.keys(teams)) {
          newTeamArr.push({
            name: key,
            count: teams[key],
          });
        }
        setTeamItems(newTeamArr);
      }
    }
  }, [careers]);

  const handleOpenLocation = useCallback(() => {
    setOpenLocation(!openLocation);
    if (!openLocation) {
      setLabelLocation('Choose Location(s)');
    } else if (selectedLocation.length > 0) {
      setLabelLocation(
        `${selectedLocation.length} ${
          selectedLocation.length === 1 ? 'filter' : 'filters'
        } applied`
      );
    } else {
      setLabelLocation('All locations');
    }
  }, [openLocation, selectedLocation.length]);

  const handleOpenTeam = useCallback(() => {
    setOpenTeam(!openTeam);
    if (!openTeam) {
      setLabelTeam('Choose Team(s)');
    } else if (selectedTeam.length > 0) {
      setLabelTeam(
        `${selectedTeam.length} ${
          selectedTeam.length === 1 ? 'filter' : 'filters'
        } applied`
      );
    } else {
      setLabelTeam('All teams');
    }
  }, [openTeam, selectedTeam.length]);

  const handleCheckLocation = useCallback(
    ({ target }) => {
      if (target.checked) {
        setSelectedLocation([...selectedLocation, target.value]);
      } else {
        setSelectedLocation(
          selectedLocation.filter(item => item !== target.value)
        );
      }
    },
    [selectedLocation]
  );

  const handleCheckTeam = useCallback(
    ({ target }) => {
      if (target.checked) {
        setSelectedTeam([...selectedTeam, target.value]);
      } else {
        setSelectedTeam(selectedTeam.filter(item => item !== target.value));
      }
    },
    [selectedTeam]
  );

  const handleClearFilter = useCallback(() => {
    setTimeout(() => {
      setSelectedTeam([]);
      setSelectedLocation([]);
      setLabelTeam('All teams');
      setLabelLocation('All locations');
    }, 100);
  }, []);

  const filteredCareers = useMemo(() => {
    let filteredList = newList.map(team => ({
      ...team,
      name: team.name === 'null' ? 'General' : team.name,
    }));

    // both filter selected (location and team)
    if (selectedLocation.length > 0 && selectedTeam.length > 0) {
      filteredList = filteredList
        .map(team => {
          return {
            ...team,
            jobs: team.jobs.filter(job =>
              selectedLocation.includes(job.location.name.trim())
            ),
          };
        })
        .filter(team => selectedTeam.includes(team.name));
    }

    // only location filter selected
    if (selectedLocation.length > 0 && !selectedTeam.length) {
      filteredList = filteredList.map(team => {
        return {
          ...team,
          jobs: team.jobs.filter(job =>
            selectedLocation.includes(job.location.name.trim())
          ),
        };
      });
    }

    // only team filter selected
    if (!selectedLocation.length && selectedTeam.length > 0) {
      filteredList = filteredList.filter(team =>
        selectedTeam.includes(team.name)
      );
    }

    return filteredList
      .filter(team => team.jobs.length > 0)
      .sort(
        (a, b) =>
          (a.name === 'General') - (b.name === 'General') ||
          a.name.localeCompare(b.name)
      );
  }, [newList, selectedLocation, selectedTeam]);

  const jobsCount = useMemo(
    () =>
      filteredCareers && filteredCareers.length > 0
        ? filteredCareers.map(job => job.jobs.length).reduce((a, b) => a + b, 0)
        : 0,
    [filteredCareers]
  );

  return (
    <Section id={id} style={customSectionStyles} bgColor={colors.base.white}>
      <JobContainer>
        <h2 className="section-title">Open Careers at Shopmonkey</h2>
        <Filters
          ref={filterRef}
          openLocation={openLocation}
          openTeam={openTeam}
          labelLocation={labelLocation}
          labelTeam={labelTeam}
          locationItems={locationItems}
          teamItems={teamItems}
          selectedLocation={selectedLocation}
          selectedTeam={selectedTeam}
          handleCheckLocation={handleCheckLocation}
          handleCheckTeam={handleCheckTeam}
          handleOpenLocation={handleOpenLocation}
          handleOpenTeam={handleOpenTeam}
        />

        <div className="jobs-container">
          <ResultFilterText
            jobsCount={jobsCount}
            isWithActiveFilter={
              selectedTeam.length > 0 || selectedLocation.length > 0
            }
            onClearFilter={handleClearFilter}
          />

          {newList && <JobsList jobs={filteredCareers} />}
          <div className="joblist-footer">
            <p className="mb-0 mr-2">Not finding what you're looking for?</p>
            <Link to="mailto:info@shopmonkey.io">Contact us</Link>
          </div>
        </div>
      </JobContainer>
    </Section>
  );
};

export default JobsComponent;
